import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { PageContext } from "@util/types";
import { Component, Hero } from "@components";
import FooterSections from "@shared/footer/footerSections";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

export default function IndexPage({ data, pageContext }: Props) {
  if (!data?.sanityHomePage) return null;

  const { seo, hero, components, pageFooter } = data.sanityHomePage;
  return (
    <>
      <SEO seoData={seo} slug={pageContext.pagePath} />

      <Hero data={hero} />
      {components &&
        components.map(component => {
          if (component == null) return null;
          return <Component key={component._key} data={component} />;
        })}
      <FooterSections pageFooter={pageFooter} />
    </>
  );
}

export const query = graphql`
  query HomeQuery {
    sanityHomePage {
      seo {
        ...sanitySeo
      }
      hero {
        ...sanityFullWidthImage
      }
      components {
        ...sanityComponents
      }
      pageFooter {
        ...sanityPageFooter
      }
    }
  }
`;
